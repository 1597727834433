<template>
  <div class="content-box">
    <!-- 背景图 -->
    <div class="box_banner"></div>
    <!-- 内容 -->
    <div class="box_wrap">
      <!-- 搜索 -->
      <!-- :class="visible?'visible_false':'visible_true'" -->
      <div class="box_title" :style="{marginTop: visible?'70px':'40vh',transform: visible?'none':'translateY(-50%)'}">
        <!-- tab -->
        <div class="title_tab">
          <div 
            class="tab_list"
            :class="{'active': tabIndex == index}"
            v-show="index != 2"
            v-for="(item, index) in tabList" 
            :key="index"
            @click="changeTab(index)">
            <img :style="{borderColor: item.color}" :src="item.imgSrc" alt="">  
            <div :style="{borderTopColor: item.color}" class="style"></div>
          </div>
        </div>
        <!-- 选择框 -->
        <div class="title_input">
          <div class="input_wrap">
            <!-- 成绩查询 -->
            <template v-if="tabIndex == 0">
              <a-select :dropdownMatchSelectWidth="false" v-model="examCourseId" placeholder="请选择考试课程">
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option :value="item.courseId" v-for="item in examCourseList" :key="item.courseId" :title="item.courseName">
                  {{item.courseName}}
                </a-select-option>
              </a-select>
              <span class="list_line"></span>
              <a-input v-model="searchName" placeholder="请输入姓名" style="width:200px;" />
              <span class="list_line"></span>
              <a-input v-model="searchCardId" placeholder="请输入身份证号" style="width:250px;" />
            </template>
            <!-- 证书查询 -->
            <template v-if="tabIndex == 1">
              <a-select :dropdownMatchSelectWidth="false" v-model="tempId" placeholder="选择证书类型">
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option :value="item.tempId" v-for="item in certifTempList" :key="item.tempId" :title="item.name">
                  {{item.name}}
                </a-select-option>
              </a-select>
              <span class="list_line"></span>
              <a-input v-model="searchName" placeholder="请输入姓名" style="width:200px;" />
              <span class="list_line"></span>
              <a-input v-model="searchCardId" placeholder="请输入身份证号" style="width:250px;" />
            </template>
            <!-- 中康协线下培训证书查询 -->
            <template v-if="tabIndex == 2">
              <a-select v-model="gardeTimeId" style="min-width:115px" placeholder="请选择班期">
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option :value="item" v-for="(item,index) in gardeTimeList" :key="index">
                  {{item}}
                </a-select-option>
              </a-select>
              <span class="list_line"></span>
              <a-input v-model="searchName" placeholder="请输入姓名" style="width:200px;" />
              <span class="list_line"></span>
              <a-input v-model="searchCardId" placeholder="请输入身份证号" style="width:250px;" />
            </template>
            <!-- 机构查询 -->
            <template v-if="tabIndex == 3">
              <Province 
                @regionCity='getPgAddressCode' 
                pgPlaceholder='选择地区'
                :class="{'regionWidth':pgAddressValue==0}"
                :setIcon="setIcon"
                :addressValue="pgAddressValue"
                v-model="pgAddressValue" ></Province>
              <span class="list_line"></span>
              <a-select v-model="type" style="min-width:132px; max-width: 250px;" placeholder="选择机构类型">
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option value="2">
                  康复救助经办机构
                </a-select-option>
                <a-select-option value="1">
                  康复救助定点服务机构
                </a-select-option>
              </a-select>
              <span class="list_line"></span>
              <a-input v-model="searchName" placeholder="请输入机构名称" style="width:150px;" />
            </template>
            <!-- 教师查询 -->
            <template v-if="tabIndex == 4">
              <a-input v-model="searchName" placeholder="请输入姓名" style="width:250px;" />
              <span class="list_line"></span>
              <a-input v-model="searchCardId" placeholder="请输入身份证号" style="width:250px;" />
            </template>
          </div>
          <div class="input_button all-btn-small" @click="queryList(true)">立即查询</div>
          <div class="mask" v-if="!$store.state.userInfo.userId" @click="queryList(true)"></div>
        </div>
      </div>
      <!-- 搜索结果 -->
      <div class="box_con" v-show="visible">
        <div class="con_info">
          <div class="info_left">查询结果</div>
          <div class="info_right" v-if="tabIndex==3">共<span class="right_num">{{total}}</span>个搜索结果</div>
        </div>
        <div class="con_bg"></div>
        <div class="con_main">
          <!-- 成绩查询 -->
          <div class="main_box main_box1" v-if="tabIndex == 0">
            <!-- 身份信息 -->
            <div class="box_top" v-if="achievement.length">
              <div class="info_title">{{achievement[0].courseName}}</div>
              <div class="info_con">
                <div class="con_list">
                  <span class="list_left">姓名：</span>
                  <span class="list_right">{{search.name}}</span>
                </div>
                <div class="con_list">
                  <span class="list_left">身份证号：</span>
                  <span class="list_right">{{search.idcard}}</span>
                </div>
              </div>
            </div>
            <!-- 成绩 -->
            <div class="box_list" v-if="achievement.length">
              <div class="info_con" v-for="(item,index) in achievement[0].examList" :key="index">
                <div class="con_list">
                  <span class="list_left">考试时间：</span>
                  <span class="list_right">{{item.examDate}}</span>
                </div>
                <div class="con_list">
                  <span class="list_left">考试成绩：</span>
                  <span class="list_right right_light">{{item.totalScore}}分</span>
                </div>
                <div class="list_icon">
                  <img src="@/assets/image/query/grade_icon1.png" v-if="item.examStatus == '未通过'" alt="未通过">
                  <img src="@/assets/image/query/grade_icon2.png" v-else alt="已通过">
                </div>
              </div>
            </div>
          </div>
          <!-- 证书查询 -->
          <div class="main_box main_box2" v-if="tabIndex == 1">
            <!-- 身份信息 -->
            <div class="box_top">
              <div class="info_title">{{search.name}}</div>
              <div class="info_con">
                <div class="con_list">
                  <span class="list_left">身份证号：</span>
                  <span class="list_right">{{search.idcard}}</span>
                </div>
                <!-- <div class="con_list">
                  <span class="list_left">性 别：</span>
                  <span class="list_right">女</span>
                </div> -->
              </div>
            </div>
            <!-- 证书 -->
            <div class="box_list">
              <div class="list_con">
                <div class="con_wrap" v-for="(item,index) in examCertifList" :key="index">
                  <div class="wrap_img" style="cursor: pointer;">
                    <img :src="item.url" @click="viewImg(item.url)" alt="">
                  </div>
                  <div class="wrap_main">
                    <div class="main_title">{{item.certifName}}</div>
                    <div class="main_info">
                      <div class="info_list">
                        <span class="list_left">证书编号：</span>
                        <span class="list_right">{{item.code}}</span>
                      </div>
                      <div class="info_list">
                        <span class="list_left">发证时间：</span>
                        <span class="list_right">{{manageDate(item.getcertifTime)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 中康协线下培训证书查询-->
          <div class="main_box main_box2" v-if="tabIndex == 2">
            <!-- 身份信息 -->
            <div class="box_top">
              <div class="info_title">{{search.name}}</div>
              <div class="info_con">
                <div class="con_list">
                  <span class="list_left">身份证号：</span>
                  <span class="list_right">{{search.idcard}}</span>
                </div>
              </div>
            </div>
            <!-- 证书 -->
            <div class="box_list">
              <!-- <div class="list_title">{{item.type}}</div> -->
              <div class="list_con" v-if="institutionCertificateObj">
                <div class="con_wrap">
                  <div class="wrap_img">
                    <img :src="institutionCertificateObj.certificatePhoto" alt="" />
                  </div>
                  <div class="wrap_main">
                    <div class="main_title">{{institutionCertificateObj.certificateName}}</div>
                    <div class="main_info">
                      <div class="info_list">
                        <span class="list_left">证书编号：</span>
                        <span class="list_right">{{institutionCertificateObj.certificateCode}}</span>
                      </div>
                      <div class="info_list">
                        <span class="list_left">发证时间：</span>
                        <span class="list_right">{{  manageDate(institutionCertificateObj.certificateTime)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 教师查询 -->
          <div class="main_box main_box2" v-if="tabIndex == 4">
            <!-- 身份信息 -->
            <div class="box_top" v-if="userMessage">
              <div class="info_title">{{userMessage.name}}</div>
              <div class="info_con">
                <div class="con_list">
                  <span class="list_left">身份证号：</span>
                  <span class="list_right">{{userMessage.idNo}}</span>
                </div>
                <div class="con_list">
                  <span class="list_left">性 别：</span>
                  <span class="list_right">{{userMessage.sex}}</span>
                </div>
              </div>
            </div>
            <!-- 证书 -->
            <div class="box_list">
              <div class="list_tab" v-if="userCertifyList.length">
                <div class="tab_con" :class="userCertifyIndex == index?'con_pick':''" @click="userCertifyIndex = index" v-for="(item,index) in userCertifyList" :key="index">{{item.type}}</div>
              </div>
              <div class="list_con" v-if="userCertifyList && userCertifyList.length">
                <div class="con_wrap" v-for="(ite,ind) in userCertifyList[userCertifyIndex].certifyVoList" :key="ind">
                  <div class="wrap_img">
                    <img :src="ite.picture" alt="" />
                  </div>
                  <div class="wrap_main">
                    <div class="main_title">{{ite.name}}</div>
                    <div class="main_info">
                      <div class="info_list">
                        <span class="list_left">证书编号：</span>
                        <span class="list_right">{{ite.code}}</span>
                      </div>
                      <div class="info_list">
                        <span class="list_left">发证时间：</span>
                        <span class="list_right">{{manageDate(ite.getcertifTime)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 机构查询 -->
          <div class="main_box main_box3" v-if="tabIndex == 3">
            <div class="box_list">
              <div class="list_con" v-for="item in mechanismList" :key="item.id">
                <div class="con_title">
                  <div class="title_main">{{item.name}}</div>
                  <div class="title_label" :class="item.type == 2?'title_label1':'title_label2'">
                    <img class="label_icon" v-if="item.type == 2" src="@/assets/image/query/institution_icon2.png" alt="经办机构">
                    <img class="label_icon" v-else src="@/assets/image/query/institution_icon1.png" alt="经办机构">
                    <span class="label_txt">{{item.type == 2?'经办机构':'定点服务机构'}}</span>
                  </div>
                </div>
                <div class="info_con">
                  <div class="con_list">
                    <span class="list1" v-if="item.operator">
                      <span class="list_left">经办人：</span>
                      <span class="list_right">{{item.operator}}</span>
                    </span>
                    <span>
                      <span class="list_left">服务电话：</span>
                      <span class="list_right">{{item.mobile}}</span>
                    </span>
                  </div>
                  <div class="con_list">
                    <span class="list_left">服务项目：</span>
                    <span class="list_right">{{item.item}}</span>
                  </div>
                  <div class="con_list">
                    <span class="list_left">地址：</span>
                    <span class="list_right">{{item.address}}</span>
                  </div>
                </div>
              </div>
            </div>
            <a-pagination :current="pageNum" :hideOnSinglePage="true" :total="total" @change="onChange" />
          </div>
          <!-- 暂无证书信息 -->
          <div class="main_empty" v-show="isHaveMessage()">
            <img src="@/assets/image/query/empty.png" alt="暂无数据">
            <span class="empty_txt">暂无{{tabIndex == 0?"成绩":tabIndex == 1?"证书":tabIndex == 2?"证书":tabIndex == 3?"机构":tabIndex == 4?"教师":""}}信息</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Province from '@/components/provinceCity'
export default {
  // 可用组件的哈希表
  components: {Province},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      // 分类
      tabList: [
        {
          id: 1, 
          title: '成绩查询',
          imgSrc: require('@/assets/image/query/tabImg1.png'),
          color: '#15B7DD'
        },
        {
          id: 2, 
          title: '证书查询',
          imgSrc: require('@/assets/image/query/tabImg2.png'),
          color: '#7B98FF'
        },
        {},
        {
          id: 4, 
          title: '机构查询',
          imgSrc: require('@/assets/image/query/tabImg3.png'),
          color: '#FFAD61'
        },
        {
          id: 5, 
          title: '教师查询',
          imgSrc: require('@/assets/image/query/tabImg4.png'),
          color: '#69E167'
        },
      ],
      tabIndex: 0,

      setIcon: true,
      addressValue: '',
      locationName: '',
      // 机构查询分页
      pageNum: 1,
      pageSize: 10,
      total: 0,

      visible: false, // 是否有查询结果
      search:{
        name: '',
        idCard: ''
      },
      // 搜索字段
      searchName: '', // 姓名/机构名称
      searchCardId: '', // 身份证号
      examCourseId: undefined, // 考试课程id - 成绩查询
      tempId: undefined, // 证书模板id - 证书查询
      gardeTimeId: undefined, // 班期 - 中康协证书查询
      pgAddressValue: [], // 机构查询 - 地区
      type: undefined, // 机构查询-机构类型 2定点服务机构、1经办机构
      userCertifyIndex: 0,
      // 搜索结果
      achievement: [], // 成绩查询结果
      mechanismList: [], // 机构查询结果
      userMessage: null, // 教师信息
      userCertifyList: [], // 教师查询结果
      examCertifList: [], // 证书查询结果
      institutionCertificateObj: null, // 中康协证书查询结果
      // 考试课程列表
      examCourseList: [],
      // 证书模板列表
      certifTempList: [],
      // 证书班期列表
      gardeTimeList: [],
    }
  },
  // 事件处理器
  methods: {
    // 查看证书
    viewImg(e) {
      window.open(e)
    },
    // 处理日期
    manageDate(con) {
      if (!con) {return}
      let year = con.split('-')[0]
      let month = con.split('-')[1]
      return year+'年'+month+'月'
    },
    // 是否有数据
    isHaveMessage(){
      if(this.tabIndex == 0){
        return !(this.achievement && this.achievement.length);
      }else if(this.tabIndex == 1){
        return !this.examCertifList.length;
      }else if(this.tabIndex == 2){
        return !this.institutionCertificateObj;
      }else if(this.tabIndex == 3){
        return !this.total;
      }else if(this.tabIndex == 4){
        return !this.userCertifyList.length;
      }
    },
    // 切换分类
    changeTab(index) {
      this.search = {
        name: '',
        idCard: ''
      };
      this.total = 0;
      this.pageNum = 1;
      this.visible = false;
      this.userMessage = false;
      this.searchName = ''; // 姓名/机构名称
      this.searchCardId = ''; // 身份证号
      this.examCourseId = undefined; // 考试课程id - 成绩查询
      this.tempId = undefined; // 证书模板id - 证书查询
      this.gardeTimeId = undefined; // 班期 - 中康协证书查询
      this.pgAddressValue = []; // 机构查询 - 地区
      this.type = undefined; // 机构查询-机构类型 2定点服务机构、1经办机构
      this.tabIndex = index;
    },
    // 分页切换
    onChange(current) {
      this.pageNum = current;
      document.documentElement.scrollTop = 0;
      this.queryList();
    },
    // 获取籍贯
    getPgAddressCode(data, name){
      this.pgAddressValue = data
    },
    // 获取所在地
    getAddressCode(data, name) {
      // this.form.setFieldsValue({['address']: data})
      this.addressValue = data
      this.locationName = name
    },
    // 查询考试课程
    getExamCourseList() {
      this.$ajax({
        url: '/hxclass-pc/course/exam-course',
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$set(this,'examCourseList',res.data);
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 查询证书列表
    getCertifTempList() {
      this.$ajax({
        url: '/hxclass-pc/user-certif/getCertiftempOption',
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$set(this,'certifTempList',res.data);
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 查询证书班期列表
    getGardeTimeList() {
      this.$ajax({
        url: '/hxclass-pc/cncard/certificate/query/schedule',
        method:'get'
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.$set(this,'gardeTimeList',res.data);
        }else{
          this.$message.error(res.message);
        }
      })
    },
    // 立即查询
    queryList(e){
      if(!this.$store.state.userInfo.userId){
        return this.$router.push('/login/loginIndex?type=2')
      }
      if(e){
        this.pageNum = 1;
      }
      let url = '',
          _params = null;
      // 成绩查询
      if(this.tabIndex == 0){
        if(!this.examCourseId){
          return this.$message.error('请选择考试课程');
        }else if(!this.searchName){
          return this.$message.error('请输入姓名');
        }else if(!this.searchCardId){
          return this.$message.error('请输入身份证号');
        }
        url = '/hxclass-pc/exam/score/query',
        _params = {
          courseId: this.examCourseId,
          name: this.searchName,
          idcard: this.searchCardId,
        }
      }
      // 证书查询
      else if(this.tabIndex == 1){
        if(!this.tempId){
          return this.$message.error('请选择证书类型');
        }else if(!this.searchName){
          return this.$message.error('请输入姓名');
        }else if(!this.searchCardId){
          return this.$message.error('请输入身份证号');
        }
        url = '/hxclass-pc/exam/certif/query',
        _params = {
          tempId: this.tempId,
          name: this.searchName,
          idcard: this.searchCardId,
        }
      }
      // 中康协证书查询
      else if(this.tabIndex == 2){
        if(!this.gardeTimeId){
          return this.$message.error('请选择班期');
        }else if(!this.searchName){
          return this.$message.error('请输入姓名');
        }else if(!this.searchCardId){
          return this.$message.error('请输入身份证号');
        }
        url = '/hxclass-pc/cncard/certificate/query',
        _params = {
          courseName: this.gardeTimeId,
          name: this.searchName,
          idNumber: this.searchCardId,
        }
      }
      // 机构查询
      else if(this.tabIndex == 3){
        if(!this.pgAddressValue.length){
          return this.$message.error('请选择地区');
        }else if(!this.type){
          return this.$message.error('请选择机构类型');
        }
        // else if(!this.searchName){
        //   return this.$message.error('请输入机构名称');
        // }
        url = '/hxclass-pc/institution/list',
        _params = {
          area: this.pgAddressValue[2]?this.pgAddressValue[2]:'', //区
          city: this.pgAddressValue[1]?this.pgAddressValue[1]:'', //市
          name: this.searchName, //机构名称
          province: this.pgAddressValue[0], //省
          type: this.type, //机构类型：1.定点机构 2.经办机构
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
      // 教师查询
      else if(this.tabIndex == 4){
        if(!this.searchName){
          return this.$message.error('请输入姓名');
        }else if(!this.searchCardId){
          return this.$message.error('请输入身份证号');
        }
        url = '/hxclass-pc/user/certify/vague',
        _params = {
          name: this.searchName,
          idcard: this.searchCardId,
        }
      }
      this.search.name = this.searchName;
      this.search.idcard = this.searchCardId;
      // return
      this.$ajax({
        url,
        method: 'get',
        params: _params
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.visible = true;
          // 成绩查询
          if(this.tabIndex == 0){
            this.achievement = res.data;
          }
          // 证书查询
          else if(this.tabIndex == 1){
            if(res.data && res.data.length){
              this.examCertifList = res.data;
              // this.$ajax({
              //   url:'/hxclass-pc/exam/certif/query/get',
              //   method:'get',
              //   params:{
              //     certifId:res.data[0].certifId
              //   }
              // }).then(data=>{
              //   if(data.code == 200 && data.success){
                  
              //   }else{
              //     this.examCertifList = [];
              //     this.$message.error(data.message);
              //   }
              // })
            }else{
              this.examCertifList = [];
            }
          }
          // 中康协证书查询
          else if(this.tabIndex == 2){
            if(res.data.length){
              this.$ajax({
                url:'/hxclass-pc/cncard/certificate/query/'+res.data[0].id,
                method:'get'
              }).then(data=>{
                if(data.code == 200 && data.success){
                  this.institutionCertificateObj = data.data;
                }else{
                  this.institutionCertificateObj = null;
                  this.$message.error(data.message);
                }
              })
            }else{
              this.institutionCertificateObj = null;
            }
          }
          // 机构查询
          else if(this.tabIndex == 3){
            this.mechanismList = res.data.records;
            this.total = res.data.total;
          }
          // 教师查询
          else if(this.tabIndex == 4){
            console.log(res)
            if(res.data){
              this.userMessage = res.data;
              // 用户查询-详情-证书复制接口复制文档复制地址
              this.$ajax({
                url:'/hxclass-pc/user/certify/user/detail',
                method:'get',
                params:{
                  userId: res.data.userId
                }
              }).then((data)=>{
                if(data.code == 200 && data.success){
                  this.userCertifyList = data.data;
                  console.log(this.userCertifyList)
                }else{
                  this.userCertifyList = [];
                  this.$message.error(data.message);
                }
              })
            }else{
              this.userMessage = null;
              this.userCertifyList = [];
            }
          }
        }else{
          this.$message.error(res.message);
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    // 查询考试课程
    this.getExamCourseList();
    // 查询证书模板列表
    this.getCertifTempList();
    // 查询证书班期列表
    this.getGardeTimeList();
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  * {box-sizing: border-box;}
  /deep/.ant-pagination{
    text-align: center;

  }
  .content-box {
    position: relative;
    min-height: 750px;
    background-color: #F6F6FC;
    // 背景图
    .box_banner {
      position: absolute;
      top: 60px;
      width: 100%;
      height: 250px;
      background-image: url(~@/assets/image/query/newbanner.png);
      background-size: auto 100%;
      background-position: center;
    }
    // 内容
    .box_wrap {
      position: relative;
      z-index: 10;
      width: 1000px;
      margin: 0 auto;
      padding-top: 60px;
      // 提示语
      .box_info {
        font-size: 20px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 30px;
        text-align: center;
        padding: 80px 38px 0px 38px;
      }
      // 搜索
      .box_title {
        margin-top: 70px;
        .title_tab {
          display: flex;
          justify-content: space-between;
          margin-bottom: 28px;
          .tab_list {
            position: relative;
            img{
              width: 230px;
              height: 260px;
              border-radius: 2px 2px 2px 2px;
              cursor: pointer;
            }
            img:hover{
              box-shadow: 4px 4px 10px 0px rgba(0,0,0,0.15);
              border: 2px solid #7B98FF;
              position: relative;
            }
            .style{
              width: 0;
              height: 0;
              position: absolute;
              left: 50%;
              bottom: 16px;
              transform: translateX(-50%);
              z-index: 2;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 6px solid #000;
              display: none;
            }
          }
          .active{
            img{
              box-shadow: 4px 4px 10px 0px rgba(0,0,0,0.15);
              border: 2px solid #7B98FF;
              position: relative;
            }
            .style{
              display: block;
            }
          }
        }
        // 选择框
        .title_input {
          display: flex;
          align-items: center;
          width: 100%;
          position: relative;
          background-color: #FFFFFF;
          .input_wrap {
            display: flex;
            align-items: center;
            width: 100%;
            height: 52px;
            // background: #F4FCFF;
            border-radius: 5px 0 0 5px;
            opacity: 1;
            border: 1px solid rgba(21,183,221,0.11);
            border-right: 0;
            padding: 0 16px;
            // select
            /deep/.ant-select {
              width: 350px;
            }
            /deep/.ant-select-selection--single {
              height: 42px;
            }
            /deep/.ant-select-selection__rendered {
              line-height: 40px;
            }
            /deep/.ant-select-selection {
              font-size: 14px;
              border: 0;
              background-color: transparent;
            }
            /deep/.ant-select-focused .ant-select-selection, 
            /deep/.ant-select-selection:focus, 
            /deep/.ant-select-selection:active {
              border: 0;
              box-shadow: none;
            }
            // input
            /deep/.ant-input {
              // width: 120px;
              font-size: 14px;
              border: 0;
              background-color: transparent;
            }
            /deep/.ant-input:focus {
              box-shadow: none;
            }
            // cascader
            /deep/.ant-cascader-picker {
              width: 100px;
              background-color: transparent;
            }
            /deep/.ant-cascader-picker:focus {
              border: 0;
              box-shadow: none;
            }
            .regionWidth /deep/.ant-cascader-input {
              display: inline-block;
              width: 60px;
              padding: 0;
            }
            /deep/.ant-cascader-input {
              display: none;
              width: 0px;
              padding: 0;
            }
            /deep/.ant-cascader-picker-label {
              position: relative;
              padding: 0 30px 0 12px;
            }
            .list_line {
              display: inline-block;
              width: 1px;
              height: 24px;
              background: #DBF4FB;
              border-radius: 0px 0px 0px 0px;
              opacity: 1;
              margin: 0 10px;
            }
          }
          .input_button {
            width: 150px;
            height: 52px;
            line-height: 52px;
            font-size: 15px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            border-radius: 0px 4px 4px 0px;
          }
          .mask{
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 6;
          }
        }
      }
      // 搜索结果
      .box_con {
        position: relative;
        margin-top: 28px;
        padding-bottom: 45px;
        .con_info {
          display: flex;
          justify-content: space-between;
          // padding: 0 20px;
          .info_left {
            font-size: 20px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #333333;
            line-height: 30px;
          }
          .info_right {
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            .right_num {
              font-size: 24px;
              margin: 0 2px;
            }
          }
        }
        .con_bg {
          position: relative;
          width: 100%;
          height: 22px;
          background: rgba(255,255,255,0.43);
          border-radius: 12px 12px 0 0;
          margin-top: 20px;
        }
        .con_main {
          position: relative;
          width: 100%;
          padding: 30px 30px 20px 30px;
          border-radius: 12px;
          background-color: #FFFFFF;
          margin-top: -12px;
          .main_box {
            .con_list {
              margin-right: 78px;
              .list_left {
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #666666;
              }
              .list_right {
                font-size: 14px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                margin-left: 12px;
              }
            }
            .box_top {
              padding-bottom: 20px;
              border-bottom: 1px solid #EBEBEB;
              .info_title {
                font-size: 16px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #333333;
                line-height: 24px;
              }
              .info_con {
                display: flex;
                margin-top: 20px;
              }
            }
          }
          // 成绩查询
          .main_box1 {
            .box_list {
              .info_con {
                position: relative;
                padding: 18px 0 30px 0;
                border-bottom: 1px solid #EBEBEB;
                &:nth-last-child(1) {
                  border: 0;
                  padding-bottom: 0;
                }
                .con_list {
                  margin-bottom: 20px;
                  &:nth-last-child(2) {
                    margin-bottom: 0;
                  }
                  .right_light {
                    color: #15B7DD;
                  }
                }
              }
              .list_icon {
                position: absolute;
                right: 30px;
                top: 10px;
                img {
                  width: 72px;
                }
              }
            }
          }
          // 证书查询/中康协线下培训证书查询/教师查询
          .main_box2 {
            .box_list {
              padding-top: 20px;
              .list_title {
                font-size: 20px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #333333;
                line-height: 30px;
                margin-bottom: 30px;
              }
              .list_tab {
                display: inline-flex;
                height: 34px;
                line-height: 34px;
                background: rgba(21,183,221,0.1);
                padding: 0 30px;
                margin-bottom: 20px;
                margin-left: -30px;
                .tab_con {
                  font-size: 16px;
                  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                  font-weight: 400;
                  color: #666666;
                  margin-right: 30px;
                  padding: 0 18px;
                  cursor: pointer;
                  &:nth-last-child(1) {
                    margin: 0;
                  }
                  &.con_pick {
                    color: #FFFFFF;
                    background: #15B7DD;
                  }
                }
              }
              .list_con {
                .con_wrap {
                  display: flex;
                  border-bottom: 1px solid #EBEBEB;
                  margin-bottom: 20px;
                  padding-bottom: 20px;
                  &:nth-last-child(1) {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border: 0;
                  }
                  .wrap_img {
                    img {
                      width: 200px;
                      // height: 196px;
                    }
                  }
                  .wrap_main {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-left: 20px;
                    padding: 20px 0;
                    .main_title {
                      font-size: 16px;
                      font-family: PingFang SC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #333333;
                      line-height: 36px;
                    }
                    .main_info {
                      .info_list {
                        font-size: 12px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        margin-top: 14px;
                        .list_left {
                          color: #666666;
                        }
                        .list_right {
                          color: #333333;
                          margin-left: 24px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          // 机构查询
          .main_box3 {
            .box_list {
              .list_con {
                border-bottom: 1px solid #EBEBEB;
                padding-bottom: 10px;
                margin-bottom: 30px;
                &:nth-last-child(1) {
                  border: 0;
                  margin-bottom: 0;
                  padding-bottom: 0;
                }
                .con_title {
                  display: flex;
                  align-items: center;
                  .title_main {
                    font-size: 16px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                  }
                  .title_label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 19px;
                    line-height: 19px;
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    padding: 3px 10px 3px 7px;
                    margin-left: 8px;
                    .label_icon {
                      width: 12px;
                      flex-shrink: 0;
                    }
                    .label_txt {
                      font-size: 12px;
                      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                      font-weight: 500;
                      margin-left: 5px;
                    }
                  }
                  .title_label1 {
                    border: 1px solid #F2A34B;
                    .label_txt {
                      color: #F2A34B;
                    }
                  }
                  .title_label2 {
                    border: 1px solid #15B7DD;
                    .label_txt {
                      color: #15B7DD;
                    }
                  }
                }
                .info_con {
                  margin-top: 20px;
                  .con_list {
                    margin-bottom: 18px;
                    &:nth-last-child(1) {
                      margin-bottom: 0;
                    }
                    .list1 {
                      display: inline-block;
                      width: 450px;
                    }
                  }
                }
              }
            }
          }
          // 暂无数据
          .main_empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0 30px 0;
            img {
              width: 221px;
            }
            .empty_txt {
              font-size: 20px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #2D3951;
              line-height: 23px;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }

</style>
